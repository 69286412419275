// h5 复制
export function copy(value, next) {
  const input = document.createElement('input');

  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', value);
  document.body.appendChild(input);

  input.select();
  input.setSelectionRange(0, 9999);

  if (document.execCommand('copy')) {
    document.execCommand('copy');
    if (next && typeof next === 'function') {
      next();
    }
  }
  document.body.removeChild(input);
}

// 判断宿主是否是浏览器
export function isBrowser() {
  return typeof window !== 'undefined';
}
