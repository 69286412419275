import React from 'react';
import '../scss/pages/redirect.scss';
import { copy } from '../utils/utils';

export default class Redirect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCoped: false
    };
  }

  copyDomain() {
    const host = window.location.host;
    copy(host);
    this.setState({
      isCoped: true
    });
  }

  render() {
    const {
      isCoped
    } = this.state;

    return (
      <div className="mobile-redirect">
        <div className="mobile-redirect-inner">
          <div className="mobile-redirect-inner__tip">请复制链接，使用电脑/ipad打开该网页</div>
          <div
            className={`mobile-redirect-inner__btn ${isCoped ? 'active' : ''}`}
            onClick={() => this.copyDomain()}
          >
            复制链接
          </div>
        </div>
      </div>
    );
  }
}
